import { ILocaleTranslation } from '../hooks/useTranslations';

export const translations: ILocaleTranslation = {
  en: {
    Projects: 'PROJECTS',
    Skills: 'SKILLS',
    Backend: 'BACKEND',
    Frontend: 'FRONTEND',
    Devops: 'DEVOPS',
    Mobile: 'MOBILE',
    Other: 'OTHER',
    Intro:
      'Hi! My name is Pontus Johansson and I am a fullstack developer currently working in C# and ReactJS in the Fintech industry. My key goals are mostly focused on being mobile friendly, having a simple to navigate UI and reusability of code and components.'
  },
  sv: {
    Projects: 'PROJEKT',
    Skills: 'SKILLS',
    Backend: 'BACKEND',
    Frontend: 'FRONTEND',
    Devops: 'DEVOPS',
    Mobile: 'MOBIL',
    Other: 'ANNAT',
    Intro:
      'Hej! Mitt namn är Pontus Johansson och jag är för närvarande fullstackutvecklare och jobbar med C# och ReactJS för ett Fintech bolag. Mitt fokus ligger tugnt på att vara mobilvänlig, lättanvändlig UI och återanvändbarhet av kod och komponenter.'
  },
  jp: {
    Projects: 'プロジェクト',
    Skills: 'スキル',
    Backend: 'バックエンド',
    Frontend: 'フロントエンド',
    Devops: 'DEVOPS',
    Mobile: 'モバイル',
    Other: 'ほか',
    Intro:
      '初めまして！Pontus Johanssonと申します。現在フルスタックのソフトウェアエンジニアとして働いています。C#　と　ReactJS　でプログラミングをしています。フォーカスはモバイルフレンドリーで使いやすい　UI　とコードの　Reusability　が大事だと思います。よろしくお願いします。'
  }
};
