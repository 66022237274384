import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from '../../hooks/useTranslations';
import { translations } from './translations.i18n';
import { AppContext } from '../../context/AppContextProvider';
import { AppAction } from '../../reducers/AppReducer';
import { ListItemPicker } from './ListItemPicker/ListItemPicker';

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  marginBottom: '2em',
  width: '100%',

  '> div': {
    margin: '20px auto 10px auto',

    [theme.breakpoints.mobileAndLower]: {
      alignSelf: 'left',
      margin: '20px 0px 10px 0px'
    }
  }
}));

const ProjectContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  margin: '15px 0',
  fontSize: 20,
  padding: 20,
  backgroundColor: 'rgba(255, 255, 255, 0.6)',
  color: 'black',
  fontWeight: 900,
  border: '5px solid rgba(255, 255, 255, 0.4)',

  '&:hover': {
    cursor: 'pointer',
    backgroundColor: 'rgba(255, 255, 255, 0.8)'
  }
});

const TextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  margin: '15px 0',
  fontSize: 18
});

const List = styled.ul(({ theme }) => ({
  textAlign: 'left',
  padding: 0,

  [theme.breakpoints.tabletAndLower]: {
    paddingLeft: 0,
    width: '100%',
    margin: '20px 0 0 0',
    textAlign: 'left',
  },

  '>li:first-of-type': {
    marginTop: 0
  },

  '> li': {
    position: 'relative',
    margin: '0 auto',
    listStyle: 'none',
    textAlign: 'left',
    marginTop: 30,
    width: '100%',

    '&:before': {
      content: '""',
      position: 'absolute',
      border: 'solid white',
      borderWidth: '0 3px 3px 0',
      top: 8,
      left: '-25px',
      padding: 3,
      transform: 'rotate(-45deg)',
      WebkitTransform: 'rotate(-45deg)',
      msTransform: 'rotate(-45deg)'
    },

    [theme.breakpoints.tabletAndLower]: {
      width: '100%'
    }
  }
}));

const ProjectTitle = styled.h3({
  width: 'fit-content',
  margin: '0 auto',
  padding: '10px',

  ':hover': {
    cursor: 'pointer'
  }
});

const ProjectTitles = styled.div({
  width: '100%',
});

const SubTitle = styled.h3({
  margin: '0 0 0.5em 0'
});

export const WebApps: React.FC = () => {
  // const { dispatch } = useContext(AppContext);
  const t = useTranslation(translations);
  const [isListPickerOpen, setIsListPickerOpen] = useState(false);

  // const showProject = (name: string) => {
  //   dispatch({ type: AppAction.SetActiveProject, payload: name });
  // };

  return (
    <>
      <ProjectTitles>
        <ProjectTitle onClick={() => setIsListPickerOpen(!isListPickerOpen)}>Random List Item Picker</ProjectTitle>
      </ProjectTitles>
      {/*<h2>{t('WebApplications')}</h2>*/}
      <Container>
        {/*<ProjectContainer onClick={() => showProject('SCS')}>Simple Checkout System</ProjectContainer>
        <ProjectContainer onClick={() => showProject('BS')}>Binary Search</ProjectContainer>
        <ProjectContainer onClick={() => showProject('QS')}>Quick Sort</ProjectContainer>*/}
        <ListItemPicker isOpen={isListPickerOpen} />
        <TextContainer>
          <SubTitle>Fintech work</SubTitle>
          <p>{t('FintechIntro')}</p>
          <List>
            <li>
              Searching, filtering and handling larger data sets. User adding, deleting, editing and impersonation. Authorization and email handling.
              Creating complex user-permission groups in a one-to-many relationship between users and customers.
            </li>
            <li>
              Client portal that allow for manangement of essential work and in-site messaging and communications. Ability to cover for a colleague in
              case of absence.
            </li>
            <li>
              User portal for private persons, authorization through bankID on desktop or mobile as login method. Ability to manage and handle debts
              and create payment plans to pay off debts which includes mobile forms of payments as well using Swish. Includes ways of communication through
              in-site messaging systems.
            </li>
            <li>
              Also manage backend systems to communicate with databases and above websites to GET, POST and PUT data required for everything to work
              smoothly.
            </li>
          </List>
        </TextContainer>
      </Container>
    </>
  );
};
