import React from 'react';
import profile from './profile';

type Locale = {
  label: string;
  code: string;
};

interface I18n {
  activeLocale: string;
  availableLocales: { [key: string]: Locale };
}

interface ISkills {
  code: string;
  content: string[];
}

interface AppConfig {
  i18n: I18n;
  skills: ISkills[];
}

export const appConfig: AppConfig = profile;
export const AppConfigContext = React.createContext(appConfig);

export const AppConfigContextProvider: React.FC = ({ children }) => {
  return <AppConfigContext.Provider value={appConfig}>{children}</AppConfigContext.Provider>;
};
