export const colors = {
  dark: '#090a0a',
  background: '#0e0f0f',
  lighter: '#1d2121',
  light: '#293333',
  white: '#fff',
  orange: '#e06900',
  pink: '#bf009f',
  red: '#700d0d',
  green: '#139c23',
  yellow: '#e8d507',
  gold: '#bf9d2e',
  grey: '#8080803d'
};

export const breakpoints = {
  tabletAndLower: '@media only screen and (max-width: 800px)',
  mobileAndLower: '@media only screen and (max-width: 500px)'
};
