import React from 'react';
import styled from '@emotion/styled';

const CardContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '2em'
});

const ProjectCardContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.white,
  borderRadius: 10
}));

const ProjectCardTitle = styled.div({
  fontSize: 20,
  marginBottom: 5
});

const ProjectCardImage = styled.div<IProjectImageProps>(({ theme, image, compact }) => ({
  backgroundImage: `url(${image})`,
  backgroundRepeat: 'no-repeat',
  borderRadius: compact ? 8 : '8px 8px 0 0',
  minWidth: 150,
  minHeight: 150,
  backgroundSize: 150,

  ':hover': {
    borderRadius: '5%',
    boxShadow: `0px 0px 8px 4px ${theme.colors.white}`
  },

  [theme.breakpoints.tabletAndLower]: {
    borderRadius: compact ? 8 : '8px 0 0 8px',
    backgroundSize: 120,
    minWidth: 120,
    minHeight: 120
  }
}));

interface IProjectCardProps {
  title: string;
  image?: any;
  googlePlayUrl?: string;
  compact?: boolean;
}

interface IProjectImageProps {
  image?: any;
  compact?: boolean;
}

export const ProjectCard: React.FC<IProjectCardProps> = ({ title, image, googlePlayUrl, compact }) => {
  return (
    <CardContainer>
      <ProjectCardTitle>{title}</ProjectCardTitle>
      <ProjectCardContainer>
        <a href={googlePlayUrl} target="_blank" rel="noopener noreferrer">
          <ProjectCardImage compact={compact} image={image} />
        </a>
      </ProjectCardContainer>
    </CardContainer>
  );
};
