import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from '../hooks/useTranslations';
import { translations } from './translations.i18n';
import { AppConfigContext } from '../config/AppConfigContext';
import { SectionTitle } from './Projects';

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  margin: '0 auto',

  [theme.breakpoints.tabletAndLower]: {
    maxWidth: '100%'
  }
}));

const SkillsContent = styled.div(({ theme }) => ({
  ul: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    paddingLeft: 0,
    textAlign: 'center',
    marginBottom: '1em',

    [theme.breakpoints.mobileAndLower]: {
      width: '100%',
      flexDirection: 'column'
    },

    '> div': {
      display: 'flex',
      flexDirection: 'column',
      width: 'auto',
      padding: '0 1em',
      minWidth: '200px',

      [theme.breakpoints.mobileAndLower]: {
        width: '100%'
      }
    },

    li: {
      textAlign: 'center',
      marginBottom: 10,
      listStyle: 'none',
      fontSize: '1em'
    }
  },

  [theme.breakpoints.tabletAndLower]: {
    justifyContent: 'center',
    display: 'grid'
  }
}));

const SkillCard = styled.div({
  display: 'flex',
  width: '100%',

  '> ul': {
    flexDirection: 'column'
  }
});

const Title = styled.h2({
  borderBottom: '1px solid black',
  textIndent: '0.2em',
  margin: '0.5em 0 0 0'
});

const SkillTitle = styled.h3({
  margin: '0 0 0.5em 0'
});

interface ISkills {
  code: string;
  content: string[];
}

const RenderSkills = ({ code, content }: ISkills) => {
  return (
    <div>
      <SkillTitle>{code}</SkillTitle>
      <SkillCard>
        <ul>
          {content.map((item: string) => {
            return <li key={item}>{item}</li>;
          })}
        </ul>
      </SkillCard>
    </div>
  );
};

export const Skills: React.FC = () => {
  const t = useTranslation(translations);
  const { skills } = useContext(AppConfigContext);

  return (
    <Container id="skills">
      <Title>{t('Skills')}</Title>
      <SkillsContent>
        <ul>
          {skills.map((item: ISkills) => (
            <RenderSkills key={item.code} code={item.code} content={item.content} />
          ))}
        </ul>
      </SkillsContent>
    </Container>
  );
};
