import { ILocaleTranslation } from '../../hooks/useTranslations';

export const translations: ILocaleTranslation = {
  en: {
    AndroidApps: 'Android Apps',
    WebApplications: 'Web applications',
    FintechIntro: 'Working on both frontend and backend in several projects in Fintech, the products are related to debt collection including:'
  },
  sv: {
    AndroidApps: 'Android Appar',
    WebApplications: 'Webbapplikationer',
    FintechIntro:
      'Jobbar både med frontend och backend för ett flertal projekt under mina år inom Fintech, produkterna är relaterade till inkasso och inkluderar bland annat:'
  },
  jp: {
    AndroidApps: 'アンドロイドのアプリ',
    WebApplications: 'ウェブアプリケーション',
    FintechIntro:
      'Fintechで色んなプロジェクトでフロントエンドとバックエンド両方やっています。下は前作ったプロジェクトと今でもまだ作っているプロジェクトにあります。'
  }
};
