import { useContext } from 'react';
import { LocaleContext } from '../context/locale-context';

export interface ILocaleTranslation {
  [key: string]: {
    [key: string]: string;
  };
}

export interface ITranslationFunction {
  (key: string, ...params: any[]): string;
}

export const useTranslation = (translations: ILocaleTranslation): ITranslationFunction => {
  const { activeLocale } = useContext(LocaleContext);

  const t = (key: string, ...params: any[]) => {
    const translation = translations[activeLocale];

    const read = (translation: string, params: any[]) => {
      return translation.replace(/{(\d)}/g, (_: any, index: number) => {
        return params[index];
      });
    };

    return translation && translation[key] ? (params.length > 0 ? read(translation[key], params) : translation[key]) : `@no-param: ${key}`;
  };

  return t;
};
