import React from 'react';

export const Posts = [
  {
    Title: 'Secure keys and secrets in legacy projects',
    Date: '2022-04-23',
    Content:
      <>
        <p>These days as we rely more on technology every day it is important to keep your apps secure from the outside. With that in mind it is important that developers also are aware and up to date on security measures.</p>
        <p>One way to secure keys using Azure is with Key Vaults. Using Key Vaults we can store our keys and secrets in Azure instead of in our code and control who can access these.</p>
        <p>But using these tools with legacy projects that have not been updated is not always easy, one example where this becomes a problem is with old .NET projects.</p>
        <p>If it is not currently possible to upgrade the project to the latest framework we are still able to get the Key Vault funcationality in .NET Framework 4.7.2 projects. </p>
        <p><i>This solution is only for projects that can not be upgraded past .NET Framework 4.8 as there are better solutions for newer frameworks.</i></p>
        <br />
        <p>Following these steps we can take advantage of Azure Key Vault on older .NET Framework projects:</p>
        <br />
        <p>1) Upgrade your project target framework to <code>.NET Framework 4.7.2</code> or bigger.</p>
        <img src="https://i.imgur.com/v1d54xt.png" alt="TargetFramework" />
        <p>2) Install <code>Microsoft.Configuration.ConfigurationBuilders.Azure</code> Nuget package.</p>
        <img src="https://i.imgur.com/Hc1NhgL.png" alt="ConfigurationBuildersAzure" />
        <p>3) In your App.config add your KeyVault Name to the configBuilders.</p>
        <img src="https://i.imgur.com/TBVK3uz.png" alt="AppConfigAzureKeyVaultName" />
        <p>4) In your config file add <code>configBuilders='AzureKeyVault'</code> to your AppSettings and/or ConnectionString container tag and replace the value with a dummy value.</p>
        <img src="https://i.imgur.com/C3Nso49.png" alt="AppConfigAzureKeyVault" />
        <p>5) After that all you need to do is set up a KeyVault in Azure and add your secrets, make sure all your key vault secret names match your config names and configure the KeyVault with appropriate Access settings so that your app and/or user can authenticate to the KeyVault and read the secrets.</p>
      </>
  }
];