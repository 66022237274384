import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ActionButton } from '../../buttons/ActionButton';
import keep from './img/keep.png';
import next from './img/next.png';
import restart from './img/restart.png';

const Container = styled.div<{ open: boolean }>(({ theme, open }) => ({
  display: 'flex',
  opacity: open ? 1 : 0,
  visibility: open ? 'visible' : 'hidden',
  flexDirection: 'row',
  height: open ? 500 : 0,
  maxHeight: 500,
  minWidth: '100%',
  background: theme.colors.dark,
  margin: '0 auto',
  border: '1px solid white',
  transition: 'opacity 0.25s ease-in',

  [theme.breakpoints.tabletAndLower]: {
    minWidth: '100%',
    maxWidth: '100%',
  },

  [theme.breakpoints.mobileAndLower]: {
    flexDirection: 'column'
  }
}));

const InputBox = styled.textarea(({ theme }) => ({
  padding: 5,
  minWidth: '50%',
  resize: 'none',
  wrap: 'hard',
  backgroundColor: theme.colors.dark,
  color: 'white',

  [theme.breakpoints.mobileAndLower]: {
    minHeight: 150
  }
}));

const ResultContainer = styled.div<{ fullWidth: boolean }>(({ theme, fullWidth }) => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: fullWidth ? '100%' : '50%',
  minHeight: '100%',
  padding: '5px 10px',
}));

const ItemsRemaining = styled.div(({ theme }) => ({
  minHeight: '10%',
}));

const ItemPicked = styled.div(({ theme }) => ({
  minHeight: '50%',
  // border: '1px solid white',
  position: 'relative',

  p: {
    position: 'absolute',
    top: '0%',
    left: '50%',
    fontSize: '50px',
    transform: 'translate(-50%, 50%)',
    width: '100%',
    overflow: 'hidden',

    [theme.breakpoints.mobileAndLower]: {
      top: '30%',
      fontSize: '30px'
    }
  }
}));

const ButtonContainer = styled.div(({ theme }) => ({
  [theme.breakpoints.mobileAndLower]: {
    margin: '0 auto',
  }
}));

const ButtonImage = styled.img(({ theme }) => ({
  width: 60,
  height: 60,
  margin: 15,
  boxSizing: 'border-box',

  ':hover': {
    cursor: 'pointer',
  },

  [theme.breakpoints.mobileAndLower]: {
    width: 50,
    height: 50,
    margin: 5
  }
}));

interface IProps {
  isOpen: boolean
}

export const ListItemPicker: React.FC<IProps> = ({ isOpen }) => {
  const [list, setList] = useState<string[]>([]);
  const [failedList, setFailedList] = useState<string[]>([]);
  const [currItem, setCurrItem] = useState('');
  const [isStarted, setIsStarted] = useState(false);

  useEffect(() => {
    let elem = document.getElementsByName('inputList');
    let values = [];
    if (elem !== undefined && elem !== null) {
      values.push(document.getElementsByName('inputList').values);
      setList(list);

      if (list.length == 0 && failedList.length > 0) {
        setCurrItem(failedList[0]);
        setList(failedList.filter(x => x.length > 0));
        setFailedList([]);
      }
    }
  }, [list, setList]);

  const NextItem = () => {
    let newList = list.filter(e => e !== currItem);
    let shuffled = shuffleArray(newList);
    setCurrItem(shuffled[0]);
    setList(shuffled);
  }

  const KeepItem = () => {
    let currFailedList = failedList;
    currFailedList.push(currItem);
    setFailedList(currFailedList);
    NextItem();
  }

  const NewList = () => {
    setIsStarted(false);
    setCurrItem('');
    setFailedList([]);
    setList([]);
  }

  const GetStartItemFromList = () => {
    if (!isStarted) {
      setIsStarted(true);
      setFailedList([]);
    }
    if (list.length <= 0) setIsStarted(false);
    let shuffle = shuffleArray(list);
    setCurrItem(shuffle[0]);
  }

  const shuffleArray = (array: Array<string>) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }

    return array;
  }

  return (
    <Container open={isOpen}>
      {!isStarted && <InputBox id="inputList" name="inputList" autoFocus={false} placeholder={'Put list in here, either comma separated or one item per line'} onChange={e => {
        let input = e.currentTarget.value.trim();
        if (input.length > 0) {
          let match = input.includes(',')
            ? input.split(',')
            : input.match(/[^\r\n]+/g) as Array<string>;
          setList(match === null ? [] : match);
        }
      }} />
      }
      <ResultContainer fullWidth={isStarted}>
        <ItemsRemaining>
          {isStarted && <span>Items left: {list.length > 0 ? list.length : 0}</span>}
        </ItemsRemaining>
        <ItemPicked>
          <h3>{!isStarted && list.length <= 0 && 'Add at least one item to the list to start'}</h3>
          <p>{isStarted && list.length >= 0 && currItem}</p>
          {!isStarted && list && list.length > 0 && <ActionButton onClick={() => {
            GetStartItemFromList();
          }}>{'Start'}</ActionButton>}
        </ItemPicked>
        <ButtonContainer>
          {isStarted && <ButtonImage src={restart} onClick={() => NewList()}></ButtonImage>}
          {isStarted && list && list.length > 0 && <ButtonImage src={keep} onClick={() => KeepItem()}></ButtonImage>}
          {isStarted && list && list.length > 0 && <ButtonImage src={next} onClick={() => NextItem()}></ButtonImage>}
        </ButtonContainer>
      </ResultContainer>
    </Container>
  );
};
