import React, { useEffect, useContext, useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { Projects } from './Projects';
import { Skills } from './Skills';
import { WebAppOverlay } from '../components/overlays/WebAppOverlay';
import { AppContext } from '../context/AppContextProvider';
import { AppAction } from '../reducers/AppReducer';
import gitHub from '../img/icons/GitHub-Mark-64px.png';
import linkedIn from '../img/icons/linked-in-logo.png';
import { useTranslation } from '../hooks/useTranslations';
import { translations } from './translations.i18n';
import { Header } from '../components/header/header';
import { Footer } from '../components/footer/Footer';
import { Posts } from './BlogPosts';

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  margin: 'auto',
  flexDirection: 'row',
  maxWidth: '100%',
  height: '100%',
  maxHeight: '100%',

  [theme.breakpoints.mobileAndLower]: {
    flexDirection: 'column',
  }
}));

const Left = styled.div(({ theme }) => ({
  display: 'flex',
  backgroundColor: '#181818',
  width: '35%',
  height: '100vh',
  color: '#fff',
  padding: '0.5em 1em 0.5em 0.5em',
  justifyContent: 'end',
  overflow: 'hidden',

  [theme.breakpoints.mobileAndLower]: {
    maxHeight: '100px',
    width: '100%',
  }
}));

const LeftContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '30%',
  margin: '200px 0.5em 0 0.5em',
  width: '100%',
  textAlign: 'right',

  'ul': {
    listStyle: 'none',
    padding: 0,
    marginTop: 0,

    'li': {
      marginTop: '1em',

      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
        fontWeight: 'bold'
      }
    }
  },

  [theme.breakpoints.tabletAndLower]: {
    marginTop: '100px'
  },

  [theme.breakpoints.mobileAndLower]: {
    marginTop: '50px',
    margin: '0 0.5em 0 0.5em',
    flexDirection: 'row',

    'ul': {
      display: 'flex',
      margin: 'auto',

      'li': {
        margin: 'auto',

        '&:not(:first-of-type)': {
          paddingLeft: '1em'
        }
      }
    }
  },
}));

const Right = styled.div(({ theme }) => ({
  display: 'flex',
  width: '65%',
  height: '100vh',
  color: '#181818',
  padding: '0.5em 0.5em 0.5em 1em',
  justifyContent: 'start',
  overflowY: 'auto',

  [theme.breakpoints.mobileAndLower]: {
    width: '100%',
    overflowY: 'initial'
  },
}));

const RightContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '30%',
  textAlign: 'left',
  padding: '0 0.5em 0 0',
  marginTop: '200px',
  width: '100%',

  'p': {
    margin: 0
  },

  [theme.breakpoints.tabletAndLower]: {
    marginTop: '100px'
  },

  [theme.breakpoints.mobileAndLower]: {
    marginTop: '0',

    h1: {
      position: 'absolute',
      color: '#fff',
      top: '3em',
      left: '2.8em',
      fontSize: '1em'
    }
  },
}));

const TextContainer = styled.div({
  margin: '1em 0 0 0',
  padding: 0,

  'h2': {
    borderBottom: '1px solid black'
  },

  'h3': {
    marginBottom: '0.5em'
  }
});

const Content = styled.div({

});

const Links = styled.div({
  width: 'fit-content',
  paddingTop: '1em',
  paddingBottom: '1em',

  '> a': {
    padding: '1em',

    '&:first-of-type': {
      paddingLeft: 0
    },

    img: {
      borderRadius: '50%',
    }
  }
});

const BlogContainer = styled.div({
  display: 'flex',
  maxWidth: '100%',
});

const BlogList = styled.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%'
});

const BlogItem = styled.div({
  paddingBottom: '1.5em',
  borderBottom: '1px solid black',
  marginBottom: '1.5em',

  h1: {
    position: 'relative',
    color: 'black',
    fontSize: '1.2em',
    top: 0,
    left: 0,
    marginBottom: 0,
  },
  h2: {
    fontSize: '0.8em',
    marginTop: 0,
    fontWeight: 'normal',
    fontStyle: 'italic',
    borderBottom: 'none'
  },
});

const BlogContent = styled.div({
  p: {
    i: {
      fontSize: '0.8em'
    }
  },
  code: {
    fontFamily: 'monospace',
    fontSize: '1em',
    backgroundColor: '#f1f1f1',
    paddingLeft: '0.3em',
    paddingRight: '0.3em',
  },
  img: {
    margin: '0.3em 0.5em 0.3em 0.5em',
    maxWidth: '100%',
    height: 'auto'
  }
});

export const Homepage: React.FC = () => {
  const t = useTranslation(translations);
  const { app, dispatch } = useContext(AppContext);
  const [contentType, setContentType] = useState(0);

  const handleShowProject = useCallback(() => {
    if (app.showProjectOverlay && app.activeProject !== '') {
      dispatch({ type: AppAction.SetActiveProject, payload: '' });
    }

    dispatch({ type: AppAction.SetShowProjectOverlay, payload: !app.showProjectOverlay });
  }, [app.activeProject, app.showProjectOverlay, dispatch]);

  useEffect(() => {
    if (app.activeProject !== '' && !app.showProjectOverlay) {
      handleShowProject();
    }
  }, [handleShowProject, app.activeProject, app.showProjectOverlay]);

  const GetPosts = () => {
    return Posts.map(x =>
      <BlogItem key={x.Title}>
        <h1>{x.Title}</h1>
        <h2>{x.Date}</h2>
        <BlogContent>{x.Content}</BlogContent>
      </BlogItem>
    )
  }

  const showContent = () => {
    switch (contentType) {
      case 0: {
        return <>
          <TextContainer>
            <p>I am a full-stack developer currently working in C# and ReactJS in the Fintech industry.</p>
            <p>My key goals are mostly focused on being mobile friendly, having a simple to navigate UI and reusability of code and components.</p>
          </TextContainer>
          <TextContainer>
            <h3>Socials</h3>
            <p>Feel free to contact me on either LinkedIn or Email: <a href="mailto:pontusdev@gmail.com">pontusdev@gmail.com</a></p>
          </TextContainer>
          <Links>
            <a href="https://github.com/pontusdev" target="_blank" rel="noopener noreferrer">
              <img src={gitHub} width="32" height="32" alt="PontusDev Github" />
            </a>
            <a href="https://www.linkedin.com/in/pontus-johansson-845a8b127/" target="_blank" rel="noopener noreferrer">
              <img src={linkedIn} width="32" height="32" alt="Pontus Johansson LinkedIn" />
            </a>
          </Links>
          <TextContainer>
            <h3>Thoughts & Things</h3>
            <BlogContainer>
              <BlogList>
                {GetPosts()}
              </BlogList>
            </BlogContainer>
          </TextContainer>
        </>
      }
      case 1: {
        return <Projects />
      }
      case 2: {
        return <Skills />
      }
      case 3: {
        return <TextContainer>
          <p>I am a full-stack developer currently working in C# and ReactJS in the Fintech industry.</p>
          <p>My key goals are mostly focused on being mobile friendly, having a simple to navigate UI and reusability of code and components.</p>
        </TextContainer>
      }
      default: {
        return <div></div>
      }
    }
  }

  return (
    <>
      {/* <header>
        <Header />
      </header> */}
      <main>
        <Container id="home">
          <Left>
            <LeftContainer>
              <h1>Pontus</h1>
              <ul>
                <li onClick={() => setContentType(0)}>HOME</li>
                <li onClick={() => setContentType(1)}>PROJECTS</li>
                <li onClick={() => setContentType(2)}>SKILLS</li>
                {/* <li onClick={() => setContentType(3)}>ABOUT</li> */}
              </ul>
            </LeftContainer>
          </Left>
          <Right>
            <RightContainer>
              <h1>Johansson</h1>
              <Content>
                {showContent()}
              </Content>
            </RightContainer>
          </Right>
          {/* {app.showProjectOverlay && <WebAppOverlay />}
          <Projects />
          <Skills /> */}
        </Container>
      </main>
      {/* <footer>
        <Footer />
      </footer> */}
    </>
  );
};
