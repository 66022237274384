import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from '../hooks/useTranslations';
import { translations } from './translations.i18n';
import { MobileApps } from '../components/projects/MobileApps';
import { WebApps } from '../components/projects/webapps';

const Main = styled.div<{}>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  paddingTop: 0,
  width: '100%',

  [theme.breakpoints.tabletAndLower]: {
    paddingTop: 0,
    paddingBottom: 50
  }
}));

export const SectionTitle = styled.h1(({ theme }) => ({
  textAlign: 'left',
  letterSpacing: 5,
  borderBottom: '1px solid grey',
  paddingLeft: 10,

  [theme.breakpoints.mobileAndLower]: {
    textAlign: 'center'
  }
}));

const Title = styled.h2({
  borderBottom: '1px solid black',
  textIndent: '0.2em',
  margin: '0.5em 0 0 0'
});

export const Projects: React.FC = () => {
  const t = useTranslation(translations);

  return (
    <Main id="projects">
      <Title>{t('Projects')}</Title>
      <WebApps />
      <MobileApps />
    </Main>
  );
};
