import React from 'react';
import styled from '@emotion/styled';
import myMemo from '../../img/apps/mymemo.png';
import { ProjectCard } from '../card/ProjectCard';
import { useTranslation } from '../../hooks/useTranslations';
import { translations } from './translations.i18n';

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  maxWidth: 700,

  '> div:not(:first-of-type)': {
    marginLeft: 30
  },

  [theme.breakpoints.tabletAndLower]: {
    maxWidth: '100%',
    flexDirection: 'column',

    '> div:not(:first-of-type)': {
      marginLeft: 0,
      marginTop: 40
    }
  }
}));

const SubTitle = styled.h3({
  margin: '0 0 0.5em 0'
});

export const MobileApps: React.FC = () => {
  const t = useTranslation(translations);
  const myMemoUrl = 'https://play.google.com/store/apps/details?id=app.selith.lifegoals';

  return (
    <>
      <SubTitle>{t('AndroidApps')}</SubTitle>
      <Container>
        <ProjectCard image={myMemo} title="My Memo (2019)" googlePlayUrl={myMemoUrl} compact={true} />
      </Container>
    </>
  );
};
