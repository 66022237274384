import React, { useContext, useState, useEffect } from 'react';
import './App.css';
import styled from '@emotion/styled';
import { AppConfigContext, AppConfigContextProvider } from './config/AppConfigContext';
import { ILocaleContext, LocaleContext } from './context/locale-context';
import { useLocalStorage } from './hooks/useLocalstorage';
import { Homepage } from './screens/Homepage';
import { AppContextProvider } from './context/AppContextProvider';
import bg from './img/backgrounds/dark_stripes.png';
import { Cookies } from './components/cookies/Cookies';
import { getCookie, setCookie } from './hooks/useCookies';

const AppContainer = styled.div<{}>(({ theme }) => ({
  textAlign: 'center',
  minHeight: '100vh',
  color: theme.colors.dark,
  minWidth: 350,
  backgroundColor: '#fff'
}));

const App: React.FC = () => {
  const appConfigContext = useContext(AppConfigContext);
  const [activeLocale, setActiveLocale] = useLocalStorage('activeLocale', appConfigContext.i18n.activeLocale);
  const [locales] = useState(appConfigContext.i18n.availableLocales);
  const [terms, setTerms] = useState(getCookie("terms"));

  const localeContext: ILocaleContext = {
    activeLocale,
    locales,
    changeLocale: (locale: string) => {
      setActiveLocale(locale);
    }
  };

  useEffect(() => {
    window.document.documentElement.lang = activeLocale;
  }, [activeLocale]);

  useEffect(() => {
    setTerms(getCookie("terms"));

    if (terms === undefined || terms === "") {
      setCookie("terms", "false", 365);
    }
  }, [terms]);

  return (
    <AppConfigContextProvider>
      <LocaleContext.Provider value={localeContext}>
        <AppContextProvider>
          <AppContainer>
            <Homepage />
            {(terms === undefined || terms === "false") && <Cookies />}
          </AppContainer>
        </AppContextProvider>
      </LocaleContext.Provider>
    </AppConfigContextProvider>
  );
};

export default App;
