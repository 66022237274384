export default {
  i18n: {
    activeLocale: 'en',
    availableLocales: {
      en: {
        label: 'English',
        code: 'en'
      },
      se: {
        label: 'Svenska',
        code: 'sv'
      },
      jp: {
        label: '日本語',
        code: 'jp'
      }
    }
  },
  skills: [
    { code: 'Backend', content: ['C #', '.NET Core / Framework', 'SQL Databases', 'Visual Studio 2019', 'Xunit (Unit tests)'] },
    { code: 'Frontend', content: ['React JS', 'JavaScript', 'HTML / CSS', 'Typescript', 'Visual Studio Code'] },
    { code: 'Devops', content: ['Microsoft Azure', 'Amazon AWS (S3)', 'Sendgrid', 'Kubernetes'] },
    { code: 'Mobile', content: ['Android (Java)', 'Android Studio', 'Unity (C#)'] },
    {
      code: 'Other',
      content: ['Kanban, Agile, Slack', 'Git, IIS', 'Github, Sourcetree', 'Auth0 / Curity / Identity', 'Service Bus', 'Postman']
    }
  ]
};
