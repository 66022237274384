import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ActionButton } from '../buttons/ActionButton';
import { setCookie } from '../../hooks/useCookies';

const Container = styled.div(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  width: '100%',
  zIndex: 150,
  height: 'auto',
  backgroundColor: '#fff',
}));

const CookiesText = styled.p(({ theme }) => ({
  marginBottom: 0
}));

const ButtonContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  '> div': {
    marginTop: 10,
  },
  button: {
    marginRight: 20,
  },
}));

export const Cookies: React.FC = () => {
  const [show, setShow] = useState(true);

  const setCookies = (show: boolean) => {
    setCookie("terms", show ? "true" : "false", 365);
    setShow(false);
  }

  return show ? (
    <Container>
      <CookiesText>This website might use cookies to store information and improve your user experiences. Please accept cookies usage for the full experience.</CookiesText>
      <ButtonContainer>
        <ActionButton onClick={() => setCookies(true)}>Accept</ActionButton>
        <ActionButton onClick={() => setCookies(false)}>Reject</ActionButton>
      </ButtonContainer>
    </Container>
  ) : <></>;
};
