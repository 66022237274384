import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Theme } from './config/theme';
import * as mainTheme from './config/main';
import { ThemeProvider } from 'emotion-theming';

interface IProps {
  theme?: Theme;
}

const MainApp: React.FC<IProps> = ({ theme = mainTheme }) => (
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
);

ReactDOM.render(<MainApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
